/***********************
* Homepage
************************/
.container {
  /* margin-top: 10px; */
  margin-left: 40px;
  padding-bottom: 30px;
}

@media (max-width: 500px) {
  .container {
    margin-left: 20px;
  }
}

/***********************
* NewHomepage
************************/

.homepageContentContainer {
  display: flex;
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.homepageSideMenu {
  width: 250px;
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  margin-left: 40px;
}

.homepageSlide {
  /* min-width: 0px;
  min-height: 0px; */
  width: 70%;
  /* order: 0;
  flex: 0 1 auto; */
  align-self: flex-start;
}

.homepageLoadingAnimation {
  margin-top: 100px;
  margin-right: 100px;
}

@media (max-width: 500px) {
  .homepageContentContainer {
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-direction: column;
  }

  .homepageSlide {
    width: 100%;
  }

  .homepageLoadingAnimation {
    margin-top: 50px;
    margin-left: 10px;
  }
}

/***********************
* cursor for icon
************************/
.iconCursor {
  cursor: pointer;
}

.iconCursor:hover {
  opacity: 0.3;
}

/***********************
* Profile page
************************/
.profileContent {
  margin-top: 40px;
  margin-left: 20px;
  width: 160%;
}

.profilePage {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 180px;
}

.profileImage {
  margin-top: 20px;
  width: 80%;
  height: auto;
  float: right;
  margin-right: 20px;
}

@media (max-width: 500px) {
  .profilePage {
    flex-direction: column;
    margin-left: 30px;
  }
  .profileImage {
    width: 60%;
    height: auto;
    float: left;
  }
  .profileContent {
    margin-top: 20px;
    width: 100%;
    margin-left: 0px;
  }
}

.topContent {
  display: flex;
  justify-content: space-between;
}

.bioText {
  margin-top: 20px;
}

/***********************
* All photo pages
************************/

.photoPage {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

@media (max-width: 500px) {
  .photoPage {
    margin-left: 30px;
  }
}

.photoTitle {
  text-align: center;
  margin: auto;
}

.photoDesc {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.alignTitleAndMenuIcon {
  display: flex;
}
