.footer {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  font-size: "small";
}

p:last-child {
  margin-bottom: 30px;
}

.loaderIcon {
  margin-top: 50px;
}

.headlineContainer {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.headlineContainer > div {
  margin-top: 40px;
  margin-right: 90px;
  margin-left: 50px;
}

.logos {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .logos {
    flex-direction: column;
    justify-content: flex-start;
  }
  .headlineContainer > div {
    margin-right: 20px;
    margin-left: 30px;
  }
}

.drone {
  margin-top: 400px;
}

@media (max-width: 500px) {
  .drone {
    margin-top: 0px;
  }

  .drone > div {
    width: 60%;
    height: 60%;
  }
}
