.slick-slide img {
  max-width: 100%;
  min-width: 100%;
}

@media (max-width: 900px) {
  .slick-slider img {
    max-width: 100%;
    min-width: 100%;
  }
}
