.dropdownMenuFont {
  font-size: 11px;
}

.accordionTitleFont {
  font-size: 13px;
  color: rgba(34, 27, 31, 0.92);
}

p:last-child {
  margin-bottom: 10px;
}

.accordionSubTitleFont {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.accordionSubTitleFont:hover {
  color: #4183c4;
}

/* Individual Accordion Title Block e.g. Portfolios, Stories */
.ui.styled.accordion .title {
  font-size: 13px;
  color: rgba(34, 27, 31, 0.92);
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  padding: 0.75em 2em 0.75em 1em;
}

/* Individual According Subtitle Block e.g. Travel, Tearsheets */
.ui.styled.accordion .content {
  padding: 0.5em 1.5em 0.75em 1.5em;
}

.ui.vertical.secondary.menu .item:not(.dropdown) > .menu > .item:hover {
  color: #4183c4;
}
