.ui.image img,
.ui.image svg {
  margin-right: inherit;
  margin: auto;
}

.modalCaption {
  text-align: center;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 12px;
}
