.slideImage {
  height: 450px;
}

.photoNumber {
  font-size: xx-small;
  color: black;
  text-align: end;
}

.customSlider .awssld__content {
  background: white;
}

.customSlider .awssld__wrapper {
  width: 95%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
}

.customSlider .awssld__controls__arrow-left,
.customSlider .awssld__controls__arrow-right {
  height: 30px;
}

.customSlider .awssld__timer {
  background-color: white;
}

.photoCaption {
  margin-top: 20px;
  font-size: 11px;
}

@media (max-width: 930px) {
  .slideImage {
    height: 350px;
  }
  p:first-child {
    margin-right: 10px;
  }
  .customSlider .awssld__wrapper {
    height: 450px;
  }
}

@media (max-width: 750px) {
  .slideImage {
    height: 250px;
  }
  .photoNumber {
    margin-right: 10px;
  }
  .customSlider .awssld__controls__arrow-left,
  .customSlider .awssld__controls__arrow-right {
    height: 20px;
  }
  .customSlider .awssld__wrapper {
    height: 350px;
  }
}

@media (max-width: 420px) {
  .slideImage {
    height: 215px;
  }
  .photoNumber {
    margin-right: 10px;
  }
  .customSlider .awssld__wrapper {
    height: 350px;
  }
}

@media (max-width: 375px) {
  .slideImage {
    height: 180px;
  }
  .photoNumber {
    padding-top: 30px;
  }
  .customSlider .awssld__wrapper {
    height: 250px;
  }
}

.carousel .slide {
  background-color: white;
}

.carousel .slider-wrapper {
  margin-top: 30px;
}

/* .carousel {
  margin-top: 20px;
} */

.carousel .slide img {
  width: auto;
  height: 100%;
}

.carousel.carousel-slider .control-arrow {
  background-color: black;
  margin-top: 28px;
}

@media (max-width: 700px) {
  .carousel.carousel-slider .control-arrow {
    background-color: white;
  }
  .carousel .slide img {
    width: 90%;
    height: auto;
  }
}

.carousel .thumbs-wrapper {
  margin: 45px;
}

.carousel .carousel-status {
  padding: 40px;
  text-shadow: none;
  color: black;
}
