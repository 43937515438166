.thumbnail {
  margin-top: 28px;
}

/* .caption {
  color: black;
  font-size: 12px;
  padding: 3px;
} */

/* .captionBox {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
} */

.singleImage {
  width: 250px;
  height: auto;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}

.imageContainer:hover .singleImage {
  opacity: 0.4;
}

/* .imageContainer:hover .captionBox {
  opacity: 2;
} */

.imageContainer {
  position: relative;
}

.imageContainer i {
  position: absolute;
  bottom: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
}

.container:hover .image {
  opacity: 0.3;
}
